// Only inline type now. Write the new condition to add new popup types

const makePopup = (selector, type) => {

    const $selector = $(selector);

    if (type === 'inline') {
        $selector.magnificPopup({
            src: $(this).attr('href'),
            type: type,
            removalDelay: 300,
            midClick: true,
            callbacks: {
                beforeOpen: function () {
                    // this.st.mainClass = 'mfp-zoom-out';
                    if(selector == '.js-slider-popup')  {
                        $('.js-popup-slider').owlCarousel({
                            loop: true,
                            margin: 0,
                            nav: true,
                            dots: true,
                            items: 1,
                            autoHeight:true,
                            URLhashListener: true,
                            startPosition: 'URLHash'
                        });
                    }

                    if(selector == '.js-slider-popup-2')  {
                        this.st.mainClass = 'mfp-docs';
                    }
                },

                open: function () {
                    $('.js-popup-slider').css('opacity', '0');
                    setTimeout(function () {
                        $('.js-popup-slider').css('opacity', '1');
                    },500);
                },

                beforeClose: function () {

                },

                close: function () {
                    $('.js-popup-slider').trigger('destroy.owl.carousel');
                }
            },
        })
    } else {
        console.log('Write condition for this popup type in make_popup, please');
    }

};

export default makePopup;

import * as jQuery from 'jquery';

// import hamburgerMenu from './components/menu';
import './libs/magnificPopup/jquery.magnific-popup.min';
import './libs/owlCarousel/owl.carousel';
import makePopup from './components/make-popup';

window.$ = jQuery.default;

((html) => {
    html.className = html.className.replace(/\bno-js\b/, 'js');
})(document.documentElement);

$(() => {

    makePopup('.js-slider-popup', 'inline');
    makePopup('.js-slider-popup-2', 'inline');

    // slider
    $('.js-countries-slider').owlCarousel({
        loop: true,
        margin: 40,
        nav: true,
        dots: true,
        items: 1
    });


    $('.js-reviews-slider').owlCarousel({
        loop: true,
        margin: 85,
        nav: true,
        dots: true,
        items: 2,
        responsive: {
            300 : {
                margin: 30,
                items: 1
            },
            767 : {
                margin: 30,
                items: 2,
            },
            1000 : {
                margin: 50
            },
            1199 : {
                margin: 85
            }
        }
    });

    $('.js-popup-slider-2').owlCarousel({
        loop: true,
        margin: 0,
        nav: true,
        dots: true,
        items: 1,
        autoHeight:true,
        URLhashListener: true,
        startPosition: 'URLHash'
    });

    $('.js-slider-popup').on('click', function () {
        const target = $(this).attr('href');
        window.location.href = target;
        return false;
    });

    $('.js-form-popup').on('click', function () {
        $('a[href="#popup:travel"]').trigger('click');
        $('a[href="#popup:travelua"]').trigger('click');
    });

    $('.popup-slider__item-image').on('click', function () {
        $('.mfp-close').trigger('click');
        setTimeout(function () {
            $('a[href="#popup:travel"]').trigger('click');
            $('a[href="#popup:travelua"]').trigger('click');
        },300);
    });

    // scroll to selected section
    $('#t-header a.t-menu__link-item, #nav263934597 a.t-menu__link-item').on('click',function (e) {
        let headerHeight;

        if($('#t-header').length) {
            headerHeight = $('#t-header').outerHeight();
        }

        if($('#nav263934597').length) {
            headerHeight = $('#nav263934597').outerHeight();
        }

        if(this.hash) {
            e.preventDefault();
        }

        let target = this.hash,
            $target = $(target);

        $('html, body').stop().animate({
            'scrollTop': $target.offset().top - headerHeight
        }, 800, 'swing');
    });

    // for analytics
    let $formBtn = $('.t-form .t-form__submit .t-submit');
    let $headerBtn = $('#nav263934597 a[href^="#popup:"]');
    let $headerBtnRus = $('#nav263931498 a[href^="#popup:"]');
    let $tel = $('#nav263934597 a[href^="tel:"]');
    let $telRus = $('#nav263931498 a[href^="tel:"]');
    let $footerBtn = $('#rec258875823 a[href^="#popup:"]');
    let $footerBtnRus = $('#rec224418493 a[href^="#popup:"]');
    let $clientsBtn = $('#rec258875818 a[href^="#popup:"]');
    let $clientsBtnRus = $('#rec247272237 a[href^="#popup:"]');

    function setAnalyticsAttrs(elem, action, value) {
        elem.attr('data-analytics-action', action);
        elem.attr('data-analytics-value', value);
        elem.addClass('analytics-elem');
    }

    setTimeout(function () {
        if($formBtn.length) {
            setAnalyticsAttrs($formBtn, 'submit','form-btn' );
        }
        if($headerBtn.length) {
            setAnalyticsAttrs($headerBtn, 'click','header-btn' );
        }
        if($headerBtnRus.length) {
            setAnalyticsAttrs($headerBtnRus, 'click','header-btn' );
        }
        if($tel.length) {
            setAnalyticsAttrs($tel, 'click','tel' );
        }
        if($telRus.length) {
            setAnalyticsAttrs($telRus, 'click','tel' );
        }
        if($footerBtn.length) {
            setAnalyticsAttrs($footerBtn, 'click','footer-btn' );
        }
        if($footerBtnRus.length) {
            setAnalyticsAttrs($footerBtnRus, 'click','footer-btn' );
        }
        if($clientsBtn.length) {
            setAnalyticsAttrs($clientsBtn, 'click','clients-btn' );
        }
        if($clientsBtnRus.length) {
            setAnalyticsAttrs($clientsBtnRus, 'click','clients-btn' );
        }
    },1000 );

    $(document).on('click', '.analytics-elem', function (e) {
        let analytics_action = $(this).data('analytics-action');
        let analytics_value = $(this).data('analytics-value');

        if(typeof dataLayer !== 'undefined' && analytics_action) {
            console.log('gtag: ' + analytics_action + ' - ' + analytics_value);

            //Click Button - analytics-follow-us
            //dataLayer.push({'event': 'CtaButtons', 'analytics_action': analytics_action, 'analytics_value': analytics_value});
            gtag('event', analytics_action, {'event_category': 'CtaButtons', 'event_label': analytics_value});
        }
    });
});
